<template>
  <div class="person">
    <h2 class="person__headline">{{ headline }}</h2>
    <img class="person__image" :src="image" :alt="name" />
    <div class="person__container">
      <div class="person__name iconline">
        <span class="material-symbols-outlined">badge</span>
        <span class="person__name__text iconline__text">{{ name }}</span>
      </div>
      <div class="person__email iconline">
        <span class="material-symbols-outlined">email</span>
        <a class="person__email__text iconline__text" :href="'mailto:' + email">
          {{ email }}
        </a>
      </div>
      <div class="person__phone iconline">
        <span class="material-symbols-outlined">phone_iphone</span>
        <span class="person__phone__text iconline__text">{{ phone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AbteilungsLeiter",
  props: {
    headline: String,
    image: String,
    name: String,
    email: String,
    phone: String,
  },
  methods: {},
  setup() {},
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
.person {
  font-size: 14px;
  &__container {
    background: rgba($black, 0.1);
    padding: 8px;
  }
  &__headline {
  }
  &__image {
    width: 100%;
    height: auto;
    display: block;
  }
  &__name {
    margin: 0;
    padding: 0 0 4px 0;
    &__text {
      position: relative;
      top: 3px;
      font-weight: 900;
    }
  }
  &__email {
    &__text {
    }
  }
  &__phone {
    &__text {
      position: relative;
      top: 2px;
    }
  }
}
</style>
