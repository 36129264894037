<template>
  <HeroComponente
    icon="Wappen_TSV_Goldbach_300DPI.png"
    headline="Bannertext"
    subline="Subline"
    img=""
    video="pexels-tima-miroshnichenko-6091132.webm"
    color="#ff6000"
  />

  <div class="content__container">
    <div class="content__wrapper">
      <div class="content__left">
        <h1 class="content__left__maintitle">Headline</h1>
        <p>Fließtext</p>
      </div>
      <div class="content__right">
        <!--
        <LigaTabelle headline="Kreisliga A2" />
        -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroComponente from "@/components/HeroComponente.vue";
//import LigaTabelle from "@/components/LigaTabelle.vue";

export default {
  name: "AbteilungSchuetzen",
  components: {
    HeroComponente,
    //LigaTabelle,
  },
};
</script>
