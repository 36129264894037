<template>
  <div class="footer">
    <div class="footer__text">
      @ {{ year }} Turn- und Sportverein Goldbach e.V.
      <span class="footer__spacer">|</span> Version {{ appVersion }}
      <span class="footer__spacer">|</span> Made with 🧡 in Goldbach
      <span class="footer__spacer">|</span> Impressum
      <span class="footer__spacer">|</span> Datenschutz
    </div>
  </div>
</template>

<script>
import { version } from "../../package";
export default {
  name: "FooterComponente",
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  setup() {
    const appVersion = version;

    return {
      appVersion,
    };
  },
  created() {},
};
</script>
