import HomeView from "../views/HomeView.vue";
import SponsorenView from "@/views/SponsorenView.vue";
import AbteilungFussball from "@/views/AbteilungFussball.vue";
import AbteilungSchuetzen from "@/views/AbteilungSchuetzen.vue";
import AbteilungPlatzhalter from "@/views/AbteilungPlatzhalter.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/sponsoren",
    name: "sponsoren",
    component: SponsorenView,
  },
  {
    path: "/fussball",
    name: "fussball",
    component: AbteilungFussball,
  },
  {
    path: "/schuetzen",
    name: "schuetzen",
    component: AbteilungSchuetzen,
  },
  {
    path: "/blank",
    name: "blank",
    component: AbteilungPlatzhalter,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
