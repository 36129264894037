<template>
  <div class="header">
    <div class="header__container">
      <router-link class="header__link" to="/">
        <img
          class="header__logo"
          src="@/assets/imgs/Wappen_TSV_Goldbach_300DPI-white.svg"
          alt="Wappen Turn und Sportverein Goldbach e.V. Schwarzer Schild mit weißem Querbalken"
        />
      </router-link>
      <div class="header__title">TSV Goldbach</div>
    </div>
  </div>

  <div class="nav__curtain" @click="openMenu()"></div>
  <div class="content">
    <router-view />
    <SponsorenComponente />
  </div>
  <FooterComponente />
</template>

<script>
import FooterComponente from "./components/footerComponente.vue";
import SponsorenComponente from "./components/sponsorenComponente.vue";
export default {
  name: "App",
  components: { FooterComponente, SponsorenComponente },
  data() {
    return {
      abteilungen: [
        { link: "fussball", icon: "fussball", text: "Fussball" },
        { link: "schuetzen", icon: "schuetzen", text: "Schützen" },
        { link: "blank", icon: "blank", text: "Abteilung" },
        { link: "blank", icon: "blank", text: "Abteilung" },
        { link: "blank", icon: "blank", text: "Abteilung" },
      ],
      isActive: false,
    };
  },

  mounted() {
    document.title = "TSV Goldbach";
  },
  created() {},
  methods: {
    openMenu() {
      if (!this.isActive) {
        document.querySelector(".nav__sidemenu").classList.add("active");
        document.querySelector(".nav__curtain").classList.add("active");
        this.isActive = true;
      } else {
        document.querySelector(".nav__sidemenu").classList.remove("active");
        document.querySelector(".nav__curtain").classList.remove("active");
        this.isActive = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/colors";
@import "assets/scss/app";

.header {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  //height: 80px;
  height: 60vh;
  //background: $white;
  //background: linear-gradient(    182deg,    rgba($white, 1) 50%,    rgba(235, 235, 235, 1) 100%  );
  background: transparent;
  //box-shadow: 0 0 10px rgba($black, 1);
  box-shadow: none;
  z-index: 9;
  &.small {
    position: fixed;
    height: 80px;
  }
  &__container {
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*&::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 4px;
      width: 100%;
      height: 1px;
      background: rgba($black, 1);
      opacity: 0.25;
    }*/
  }
  &__logo {
    height: 100px;
    width: auto;
    margin-right: 20px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: rgba($black, 1);
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  &__title {
    //font-family: "condensed", "Roboto", sans-serif;
    //color: rgba($black, 0.75);
    color: rgba($white, 1);
    font-weight: 900;
    font-size: 28px;
    margin-right: 60px;
    background: -webkit-linear-gradient(rgba($white, 1), rgba($white--dark, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //text-shadow: 2px 2px 2px rgba($black, 0.5);
    text-shadow: 2px 2px 0px rgba($white, 0.25);
    @media (min-width: 540px) {
      margin-right: 0;
      font-size: 82px;
    }
    text-transform: uppercase;
  }
}
.nav {
  &__sidemenu {
    display: flex;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2000;
    translate: 300px 0;
    transition: all ease 0.2s;
    &.active {
      translate: 0 0;
    }

    &__icon {
      background: rgba($white, 1);
      width: 60px;
      height: 60px;
      margin-top: 10px;
      position: absolute;
      translate: -60px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px 0 0 4px;
    }
    &__menu {
      background: rgba($white, 1);
      height: 100vh;
      width: 268px;
      padding: 16px;
    }
    &__link {
      text-decoration: none;
      display: block;
      text-align: left;
      color: rgba($black, 1);
      padding: 8px;
      &:hover,
      &.router-link-active {
        background: rgba($black, 1);
        color: rgba($white, 1);
      }
    }
  }

  &__curtain {
    //pointer-events: none;
    display: inline;
    position: fixed;

    left: 0;
    right: 0;
    background: rgba($black, 0);
    z-index: 1000;
    transition: all ease 0.3s;
    &.active {
      height: 100vh;
      display: block;
      background: rgba($black, 0.9);
    }
  }
}
.submenu {
  position: absolute;
  //top: 80px;
  top: 65vh;
  transform: translate(0, -48px);
  width: 100%;
  z-index: 9;
  &.small {
    .submenu {
      &__link {
        border-radius: 0 0 8px 8px;
        box-shadow: 0 0 1px rgba($black, 0.5) inset, 0 0 8px rgba($black, 0.5);
      }
    }
  }
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //position: relative;
    //top: -8px;
    //padding-top: 8px;
    color: rgba($black, 0.75);
    background: rgba($white, 1);
    text-decoration: none;
    border-radius: 8px 8px 0 0;
    //box-shadow: 0 0 1px rgba($black, 0.5) inset;
    border: 4px solid rgba($white, 1);
    margin: 0 2px;

    &__icon {
      display: flex;
      margin: 4px 8px;
      &__img {
        width: 32px;
        height: auto;
        &--over {
          width: 32px;
          display: none;
        }
      }
    }
    &__text {
      display: none;
      @media (min-width: 800px) {
        display: block;
        padding-right: 16px;
        //font-weight: 900;
        text-transform: uppercase;
      }
    }
    &:hover,
    &.router-link-active {
      background: rgba($black, 1);
      border-color: rgba($black, 1);
      //box-shadow: 0 0 1px rgba($white, 1) inset, 0 0 8px rgba($black, 0.5);
      box-shadow: 0 0 1px rgba($white, 1) inset, 0 0 8px rgba($white, 1);
      color: rgba($white, 1);
      .submenu__link__icon__img {
        display: none;
        &--over {
          display: block;
        }
      }
    }
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative; // hero background overflow
  //padding-top: 80px;  // hero background overflow
  background: rgba($black, 1) url("@/assets/imgs/background-wappen.png"); // hero background overflow

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    position: relative; // hero background overflow
    padding-top: 320px;
    bottom: 0; // hero background overflow
    width: 100%; // hero background overflow

    @media (min-width: 800px) {
      padding-top: 65vh;
    }
  }
  &__wrapper {
    display: flex;
    background: rgba($white, 1);
    margin: 0 12px 12px 12px;
    width: calc(100% - 24px);
    //min-width: 640px;
    max-width: 1200px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgba($black, 0.1);
    box-shadow: 0 0 6px rgba($black, 1);
    box-sizing: border-box;
    line-height: 1.65em;
    letter-spacing: -0.015em;
    text-align: left;
    @media (min-width: 640px) {
      width: calc(100% - 48px);
      margin: 0 24px 24px 24px;
    }
    &--first {
      text-align: center;
      margin: 0 0 24px 0;
      padding: 0 0 24px 0;
      p {
        padding: 0 24px;
      }
      .content {
        &__left {
          &__maintitle {
            color: rgba($black, 0.75);
            background: rgba($black, 0.1);
            margin: 0;
            padding: 48px 0 48px 0;
            @media (min-width: 840px) {
              padding: 96px 0;
            }
          }
        }
      }
    }
  }
  &__left {
    flex-grow: 1;
  }
  &__right {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40%;
    padding-left: 24px;
    @media (min-width: 640px) {
      padding-left: 48px;
    }
  }
}
</style>
