<template>
  <div class="sponsoren">
    <div class="sponsoren__text"><h4>Könner & Gönner</h4></div>
  </div>
</template>

<script>
export default {
  name: "SponsorenComponente",
  props: {},
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
.sponsoren {
  color: $white;

  padding: 32px 24px;
}
</style>
